import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Form, FormBuilder } from '@angular/forms';
import { forms_url } from 'src/app/utils/helper/url';
import { DRAFT_DOCUMENT, FINALIZED, FINAL_DOCUMENT, FOR_APPROVAL, FOR_REVISION, NEW_DOCUMENT, TRACKING_DOCUMENT } from 'src/app/utils/items/clinical-documentation-status';
import { FormService } from 'src/app/utils/services/forms/forms.service';
import { ClinicalDocumentFormTemplateComponent } from '../clinical-document-form-template/clinical-document-form-template.component';
import { ClinicalDocumentSignModalComponent } from '../clinical-document-sign-modal/clinical-document-sign-modal.component';
import { ImageAnnotatorComponent } from '../microservices/image-annotator/image-annotator.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clinical-document-form',
  templateUrl: './clinical-document-form.component.html',
  styleUrls: ['./clinical-document-form.component.scss']
})
export class ClinicalDocumentFormComponent implements OnInit {

  @Output()
  goBack: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  saveForm: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  deleted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  generatePDF: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  titleChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('formTemplate', { static: true })
  formTemplate: ClinicalDocumentFormTemplateComponent;

  @ViewChild('signDocumentModal', { static: true })
  signDocumentModal: ClinicalDocumentSignModalComponent;

  @Input()
  document = null;

  @Input()
  patient: any = null;

  @Input()
  visit: any = null;

  @Input()
  documentTemplate: any = null;

  @Input()
  values: any = null;

  @Input()
  title: any;

  loadingPDF = false;
  loadingDocumentTemplate = false;
  templateCode = null;
  visitID = null;
  patientID = null;
  draftCode = DRAFT_DOCUMENT;
  finalCode = FINAL_DOCUMENT;
  trackingCode = TRACKING_DOCUMENT;
  forApprovalCode = FOR_APPROVAL;
  forRevisionCode = FOR_REVISION;
  newCode = NEW_DOCUMENT;
  finalizedCode = FINALIZED;
  queryParams: any = {};

  // old
  uploadUrl = forms_url + '/file-uploader/multiple';
  formGroup: FormGroup;
  formLayout: any = {};
  forms: Form[];
  sourceDocuments: any;

  savingDocument = false;

  /**
   * TypeScript modifiers
   */
  constructor(
    private service: FormService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

  }

  initialize(code: number, patientID: any, visitID: any) {

    this.loadingDocumentTemplate = true;
    this.formTemplate.loading = true;
    this.patientID = patientID;
    this.visitID = visitID;

    Promise.all([
      this.service
        .getDocumentTemplate((this.templateCode = code), {
          includes: 'document_category,document_type,document_subtype',
        })
        .toPromise(),
      this.service
        .getDocuments({
          visit_id: this.visitID,
          patient_id: this.patientID,
          status: 'T',
        })
        .toPromise(),
    ]).then((responses: any) => {
      this.formTemplate.documentTemplate = this.documentTemplate = responses[0];
      const document_token = `${JSON.parse(localStorage.getItem('mwell-md-current-user'))?.doctor?.md_id}-${code}-title`;
      if (localStorage.getItem(document_token) !== null) {
        this.title = localStorage.getItem(document_token);
      }
      this.formTemplate.initializeForm(responses[1]?.data, code);

      this.loadingDocumentTemplate = false;
      console.log(this.document?.pdfs[0].pdf_url);
    });
  }

  async save(documentStatus = DRAFT_DOCUMENT, workflowStatus = NEW_DOCUMENT) {

    const promises = [];
    this.savingDocument = true;

    this.formTemplate.annotators?._results.forEach((annotate: ImageAnnotatorComponent) => {
      promises.push(annotate.save());
    });

    Promise.all(promises)
      .then((responses) => {

        const data = {
          title: this.title,
          patient_id: this.patientID,
          patient_name: this.patient?.primary_name?.display,
          visit_id: this.visitID,
          document_status_code: documentStatus,
          workflow_status_code: workflowStatus,
          template_code: this.templateCode,
          values_format: this.formTemplate?.formLayout,
          values: this.formTemplate?.formGroup?.value,
        };

        this.saveForm.emit(data);
      })
  }

  onValueChange(val) {
    this.valueChange.emit(val);
  }

  onTitleChange(val) {
    this.titleChange.emit(val)
  }

  hasApprover() {
    return false;
  }

  cancel() {
    // this.documentTemplate = null;
    // this.values = null;
    // this.document = null;
    this.cdr.detectChanges();
    // this.goBack.emit();
    this.router.navigate(['/patient/' + this.patientID + '/clinical-documentation'], {
      queryParams: { visit_id: this.visitID }, queryParamsHandling: 'preserve'
    });
  }

  onGeneratePDF(pdf) {
    this.generatePDF.emit(pdf);
  }

  counter(i: number) {
    return new Array(i);
  }

  deleteDocument() {
    this.deleted.emit(this.document?.id);
  }

  getQueryParams() {
    this.queryParams.pdf = this.document?.pdfs[0].pdf;
    this.queryParams.patientID = this.patientID;
    return this.queryParams;
  }

  sendFile() {
    /* Temporary Commented by Rey  
    This function is for sending documents to PE
    const queryParams = this.getQueryParams();
      const waitingToast = this.toastr.info('Please wait, file is sending...', '', {
      timeOut: 0,
      disableTimeOut: true,
    });
  
    this.service.sendFile(queryParams).subscribe(
      (response: any) => {
        this.toastr.clear(waitingToast.toastId);
        this.toastr.success('Clinical Document successfully sent to Patient!', '', {
          timeOut: 3000,
        });
      },
      (err) => {
        this.toastr.clear(waitingToast.toastId);
        console.log('Error:', err);
      }
    ); */

    // Code below will send all the pdfs to patient in the Doctor's worklist not to PE patient.
    // Will update this to be dynamic in the future.

    this.document.sending = true;

    this.service
      .sendDocument(this.document?.id, {
        pdfs: this.document?.pdfs?.map((item) => {
          return {
            file: item?.pdf,
            name: item?.name,
          };
        }),
      })
      .subscribe(
        (response: any) => {
          this.toastr.success('File(s) has been queued for sending!', '', {
            timeOut: 3000,
          });
          this.document.sending = false;
          this.document?.pdfs.forEach((pdf) => {
            pdf.selected = false;
          });
        },
        (err) => {
          this.document.sending = false;
        }
      );
  }
}

